








































import inButton from '@/components/base/pc/InsButton.vue';
import inPrices from '@/components/base/pc/InsPrices.vue';
import YouWouldLike from '@/model/youWouldLike';
import { Vue, Prop, Component } from 'vue-property-decorator';
@Component({ components: { inButton, inPrices } })
export default class InsProductItem extends Vue {
  private Enter: boolean = false;
  @Prop() private item!: YouWouldLike;
  @Prop() private styla!: string;
  @Prop({ default: 0 }) private index!: number;
  @Prop({ default: true }) private button!: boolean;
  @Prop({ default: false }) private borderRadius!: boolean;
  @Prop({ default: true }) private needCode!: boolean;
  buttonSubmit (item) {
    this.$router.push({
      path: '/product/detail',
      name: 'ProductsDetail',
      params: {
        id: item.Sku
      }
    });
  }
  click (e) {
    let target = e.target as HTMLElement;
    if (target.nodeName === 'IMG') {
      this.$router.push('/product/detail/' + target.dataset.key);
    }
  }
}
